@import "~@/assets/scss/variables";
































































































































































































































































































.smart-selection-form {
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    max-width: 820px;
    margin-bottom: 32px;
  }

  &::v-deep {
    .budget-slider {
      &__slider {
        margin: 50px 10px 30px;
      }
    }
  }
}
