@import "~@/assets/scss/variables";





















































.user-account-status {
  .dt-table__td--muted &,
  &--muted {
    &.entity-status-tag--active {
      --bg-color: var(--entity-status-tag-muted-bg);
      --text-color: var(--entity-status-tag-muted-color);
    }
  }
}
