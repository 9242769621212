@import "~@/assets/scss/variables";












































































.usage-stats-users-table-view {
  &::v-deep .dt-table__td {
    vertical-align: middle;
  }

  .field-title-email {
    font-size: 12px;
    line-height: 14px;
  }
}
