@import "~@/assets/scss/variables";













































































.webhooks-table-view {
  &::v-deep {
    .column-url {
      width: 300px;
      min-width: 300px;
      word-break: break-all;
    }
  }
}
