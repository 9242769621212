@import "~@/assets/scss/variables";










































































































































































































































































.users-table-view::v-deep {
  .dt-table__td {
    border-bottom: 1px solid var(--line-color-main);
  }

  .dt-table__tr.odd-row {
    background-color: var(--entity-list-table-odd-row-bg);
  }

  .subrow-column.even-row {
    background-color: var(--entity-list-table-subrows-even-row-bg);
  }
}
