@import "~@/assets/scss/variables";






































































.enable-languages {
  list-style: none;
  margin: 0 0 -10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &__icon {
    margin-right: 4px;

    img {
      width: 20px;
      display: block;
    }
  }

  &__text {
    text-transform: uppercase;
    line-height: 16px;
  }
}
