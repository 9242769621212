@import "~@/assets/scss/variables";

















































.dead-messages-table-view {
  &::v-deep .column-service-name {
    white-space: nowrap;
  }
}
