@import "~@/assets/scss/variables";






































































.webhooks-queue-table-view {
  &::v-deep {
    .column-url {
      min-width: 250px;
    }
  }
}
