@import "~@/assets/scss/variables";






















































































































.email-log-events {
  margin-top: 24px;
}
