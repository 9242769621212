@import "~@/assets/scss/variables";




































































































































































































































.selection-not-collapsible-item {
  padding: 9px 8px 9px 34px;
  border-bottom: 1px solid var(--line-color-main);

  b {
    font-weight: 500;
  }
}

.smart-selection-info::v-deep li.not-available {
  color: var(--link-disable-color);
}
