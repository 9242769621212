@import "~@/assets/scss/variables";








































































.files-table-view::v-deep {
  .field-user {
    display: flex;

    &__avatar {
      margin-right: 10px;
    }

    &__name {
      display: flex;
      align-items: center;
    }
  }
}
