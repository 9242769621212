@import "~@/assets/scss/variables";
















































.subscription-name {
  display: flex;
  align-items: center;

  &__name {
    margin-right: 4px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
}
