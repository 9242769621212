@import "~@/assets/scss/variables";



















































.field-email-event-type {
  display: flex;

  &__icon {
    --ico-color-2: var(--secondary);

    margin-right: 8px;
    height: 21px;
    display: flex;
    align-items: center;

    .svg-icon {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}
