@import "~@/assets/scss/variables";

















































































































































































.transfer-stages {
  list-style: none;
  margin: 0;
  padding: 0;
}
.task-info-columns {
  margin-bottom: 30px;
}
