@import "~@/assets/scss/variables";



































































.api-clients-table-view {
  &::v-deep {
    .column-api-client-id {
      min-width: 200px;

      @include media-breakpoint-up(xl) {
        min-width: 320px;
      }
    }
  }
}
