@import "~@/assets/scss/variables";









































































































































































































































































































































































































































































































































































.create-user-modal .modal-dialog {
  .modal-body {
    padding: 0 90px;

    .form-group {
      &:nth-child(n+2) {
        margin-top: 40px;
      }

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
}
